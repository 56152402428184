.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: tuskerGrotesk;
  src: url(TuskerGrotesk-5500Medium.ttf);
}

@font-face {
  font-family: tuskerGroteskTall;
  src: url(TuskerGrotesk-3500Medium.ttf);
}

@font-face {
  font-family: montreal;
  src: url(NeueMontreal-Regular.otf);
}

@font-face {
  font-family: migra;
  src: url(Migra-Extralight.ttf);
}

.navbar {
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 10;
  background: transparent;
  backdrop-filter: blur(10px);
  font-family: migra;
  font-weight: bold;
  font-size: 19px;
  background-color: rgba(255, 255, 255, 0.46);
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 10;
}

.nav * {
  display: inline;
}

.nav li {
  margin: 20px;
}

.logo {
  font-size: 25px;
  font-weight: 1000;
  font-family: tuskerGrotesk;
}

.App-header {
  background-color: #c0a742;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.headshot {
  max-width: 40vw;
  max-height: 80vh;
  margin-top: 80px;
}

.top-section {
  width: 100%;
  color: #FFFFFF;
  font-weight: bold;
  font-family: montreal;
}

.name
{
  font-size: 13vw;
  font-family: tuskerGroteskTall;
  color: #494848;
  position: absolute;
  top: 5%;
  right: 3%;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.description {
  align-items: center;
  justify-content: center;
  background-color: #3734346b;
  font-size: 3vw;
  position: absolute;
  bottom: 15%;
  width: 30%;
  left: 6%;
}

.bio {
  width: 100%;
  font-size: 18px;
  font-family: montreal;
  color: #FFFFFF;
  margin-top: 25%;
  text-align: right;
}

.bio-header {
  font-family: tuskerGroteskTall;
  font-size: 12vw;
}

.projects {
  margin: auto;
  padding: 100px;
  /* border: solid;
  border-width: 15px;
  border-color: #c0a742; */
}

.project-header {
  position: relative; right: 35%;
  font-family: tuskerGroteskTall;
  font-size: 12vw;
  color: #494848;
}

.bio-section {
  background-color: #6d6959;
}

.education-section {
  padding: 100px;
}

.education-header {
  font-family: tuskerGroteskTall;
  font-size: 12vw;
  color: #494848;
}

.edu-cards {
  padding-top: 5vw;
}


.main-body {
  position: absolute; top: 60px;
}

.contact-section {
  display: flex;
  align-items: center;
}

.linkedin {
  width: 50%;
}

.email {
  width: 30%;
}

.github {
  width: 40%;
}

.card-subtitle {
  font-size: 14px;
  font-weight: 500;
}

.github-logo {
  width: 30%;
}

body {
  transition: background-color 1s ease;
}

/* panel styles */
.panel {
  /* min height incase content is higher than window height */
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
}

.top-panel {
  background-image: url(images/marble-background.webp) ;
  background-size: 280%;
  background-repeat: no-repeat;
}


/* colours */
.color-white {
  background-image: url(images/marble-background.webp) ;
  background-size: 230%;
}

.color-dark {
  background-color: #6d6959;
}
.color-projects {
  background-color: #c9c6b7;
}
.color-education {
  background-color: #FFFFFF;
}


.footer {
  width: 100%;
}

.panel-item {
  flex-basis: 80%;
}

.top-row {
  display: flex;
  position: relative;
  height: 100vh;
}

.bio-row {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: space-evenly;
  gap: 10%;
}

.project-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 3%;
}

.project-card {
  margin: 5;
  padding: 0;
  flex-basis: 80%;
}

.project-text {
  background-color: #FFFFFF;
  padding: 1rem;
  min-height: 300px;
}

.edu-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 3%;
}

.edu-card {
  margin: 0;
  padding: 0;
  flex-basis: 20%;
}

.edu-text {
  padding: 1rem;
}

.contact-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 3%;
}

.contact-link {
  margin-bottom: 5px;
  padding: 0;
  flex-basis: 80%;
}

.contact-link a {
  margin: 0px;
}

.email-form {
  flex-basis: 30%;
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  margin: 70px;
}

.form-contents {
  margin-bottom: 10px;
}

.form-header {
  font-family: tuskerGroteskTall;
  padding: 10px;
}

.headshot-section {
  display: flex;
  align-items: left;
}

.project-carousel {
  display: flex;
}

.project-slides {
  height: 450px;
  background-color: #494747;
}

.slide-text {
  text-align: left;
  position: relative; 
  width: 75%;
}

.slide-image {
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .top-panel {
    background-size: 180%;
  }
  .description {
    font-size: 1.8vw;
  }
  .panel-item {
    flex-basis: 40%;
  }
  .project-card {
    flex-basis: 29.33%;
  }
  .contact-link {
    flex-basis: 30%;
    margin: 0;
  }
  .project-carousel {
    visibility: hidden;
    display: none;
  }
}

@media (min-width: 1200px) {
  .top-panel {
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .email-form {
    margin: 10px;
    flex-basis: 80%;
  }

  .headshot {
    max-width: 80vw;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .bio {
    margin-top: 0;
  }

  .nav li {
    margin: 5px;
  }

  .description {
    bottom: 50%;
    width: 50%;
  }

  .edu-card {
    flex-basis: 100%;
    font-size: 11px;
  }

  .project-row {
    visibility: hidden;
    display: none;
  }

  .project-header {
    margin-bottom: 25px;
    font-size: 20vw;
  }

  .education-header {
    font-size: 20vw;
  }

  .bio-header {
    font-size: 20vw;
  }

  .name {
    font-size: 17vw;
  }
}